import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { TextField, Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Item, Item2 } from '../../styles';
import DropPanel from '../../components/DropPanel';
import { SpectrumSearchMode, spectrumSearchModes } from '../../schemas/Spectrum/SpectrumSearchMode';

import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import RefreshError from '../../schemas/Exception/RefreshError';
import { fetchPost } from '../../services/GettingData';
import HelpIconWithHint from '../../components/HelpIconWithHint';
import IRPeaks from '../../schemas/Spectrum/IRPeaks';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';
import TimeoutError from '../../schemas/Exception/TimeoutError';

type IRSpectrumSearchProps = {};


export const SpectrumSearchPage: React.FC<IRSpectrumSearchProps> = (props) => {

    const [isInProgress, setInProgress] = React.useState<boolean>(false);
    const [irPeaks, setIrPeaks] = React.useState<IRPeaks>(() => ({
        x_req: [],
        y_req: []
    }));

    const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);

    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [showMessage, setShowMessage] = React.useState<string>('');
    const [files, setFiles] = React.useState([]);

    const closeErrorDialog = () => setDialogOpened(false);


    const showError = (errorMessage: string) => {
        setErrorMessage(errorMessage);
        setDialogOpened(true);
    }


    React.useEffect(() => {
        if (files.length > 0)
            submitSearch();
    }, [files]);


    const fileUploaded = (files: File[]) => {
        console.log('files', files);
        setFiles(files);
    };


    const submitSearch = async () => {
        let response: any;
        try {
            if (files.length > 0) {
                response = await parseDxFile();
            }
            if (response) {
                (props as any).history.push({ pathname: '/ir-spectrum-preview', state: { irPeaks: response } });
            }
            else {
                setDialogOpened(true);
                setShowMessage('File could not be processed');
            }
        }
        catch (e: any) {
            if (e instanceof TimeoutError) {
                showError('Timeout error. Please try again later');
            }
            if (e instanceof RefreshError) {
                showError(e.message);
                (props as any).history.push({ pathname: '/login', state: { backTo: '/ir-spectrum-search', welcomeMessage: true } })
            }
            if (e instanceof NotAuthorizedError) {
                (props as any).history.push({ pathname: '/login', state: { backTo: '/ir-spectrum-search', welcomeMessage: true } })
            } else {
                console.error(e.toString());
                showError(e.toString());
            }
        }
    }


    const parseDxFile = async (): Promise<IRPeaks> => {
        const defaultErrorMessage = 'Incorrect uploaded file';
        const dataTypeDx = 'multipart/form-data';
        const dataTypeZip = 'application/x-zip-compressed';

        let endPoint = '/ir-spectrum/parse';
        let file = files[0];
        let body = new FormData()
        let isDx = file.name.toLowerCase().endsWith('.dx');
        if (isDx) {
            let blobWithNewType = new Blob([file], { type: "chemical/x-jcamp-dx" });
            body.append("file", blobWithNewType, file.name);
        }
        else
            body.append('file', file);
        try {
            const response = await fetchPost(endPoint, body, true, true, isDx ? dataTypeDx : dataTypeZip, defaultErrorMessage, 3);
            const peaks = await response.json() as IRPeaks;
            setIrPeaks(peaks);
            return peaks;
        }
        catch (e: any) {
            if (e instanceof NotAuthorizedError)
                (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
            if (e instanceof ExpiredAccountError)
                (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
            if (e instanceof RefreshError) {
                showError(e.message);
                (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
            }
            if (e instanceof TimeoutError) {
                console.error(e.message);
                showError(e.message);
            }

        }
    }



    return (
        <Grid container spacing={0} className='main-frame'>
            <Grid container>
                <Item2><div style={{ height: '2em' }}></div></Item2>
            </Grid>
            <ErrorDialog isDialogOpened={isDialogOpened}
                errorMessage={errorMessage}
                warningMessage={showMessage}
                onClose={closeErrorDialog} />
            <Grid item xs={12}>
                <Header title={'Infrared spectrum search'} helpAddress='help#htuirss' showLogin={true} />
            </Grid>
            <Grid container spacing={0} style={{ marginTop: '.5em' }}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>


                </Grid>
                <Grid item md={4} xs={12} sm={12} style={{ paddingLeft: '2em' }}>
                    <Item2 style={{ fontSize: '1em', textAlign: 'left' }}>
                        <div style={{ marginTop: '1em' }}>
                            <Grid container alignItems="center" justifyContent="center" >
                                <Grid item xs={11}>
                                </Grid>
                                <Grid item xs={1} style={{ verticalAlign: 'middle' }}>
                                    <HelpIconWithHint title={''}
                                        text={'You need this field ONLY if you insert peaks to the shift-intensity table. In this case choose spectrum type, solvent and add as much peaks as you want. Otherwise do not care about these fields (spectrum type, solvent)'}
                                        handleOpen={() => { }} />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '1em' }}>
                        </div>
                    </Item2>
                </Grid>
            </Grid>

            <Grid container spacing={0} style={{ marginTop: '2em' }}>
                <Grid container
                    style={{ marginBottom: '2em' }}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">


                    <Item style={{
                        width: '100%',
                        minHeight: '6em',
                        backgroundColor: '#d5f5f5',
                        textAlign: 'center',
                        justifyContent: 'center',
                        position: 'relative'
                    }}>
                        <DropPanel onChange={fileUploaded}
                            text={"Drag and drop “.dx” file with your IR spectrum (either in transmittance or absorbance mode)"} />
                        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                            className='mol-container'>
                        </div>
                    </Item>


                </Grid>

            </Grid>

            <Grid container spacing={2} style={{ marginTop: '0em' }}>
            </Grid>

            <Grid container spacing={0} style={{ marginTop: '2em' }}>
                <Grid item md={4}></Grid>
                <Grid item md={4} xs={12} style={{ minWidth: '220px' }}>
                </Grid>
                <Grid item md={4} xs={12} className='pamd1'>
                </Grid>
            </Grid>
        </Grid>
    );

}
export default SpectrumSearchPage;
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

type ISolventProps = { anyPossible: boolean, 
                       onChange: (e: any) => void;
                       selected: string | null };

export const SolventControl: React.FC<ISolventProps> = (props) => {
    const solvents: Record<string, string> = {
    'any': 'Any',
    'acetone_d6': 'acetone d\u2086',
    'acetonitrile_d3': 'acetonitrile d\u2083',
    'C6D5_Cl': 'C\u2086D\u2085Cl',
    'C6_D6': 'C\u2086D\u2086',
    'CD2_Cl2': 'CD\u2082Cl\u2082',
    'CD3_CN': 'CD\u2083CN',
    'CD3_OD': 'CD\u2083OD',
    'CDCl_3': 'CDCl\u2083',
    'DMSO_d6': 'DMSO D\u2086',
    'D2_O': 'D\u2082O',
    'TFE_d3': 'TFE d\u2083',
    'THF_d8': 'THF d\u2088',
    'toluene_d8': 'toluene d\u2088',
    }

    let listItem = Object.keys(solvents).filter(s=> s!== 'any' || props.anyPossible).map((key: string, index: number) => 
    { return (<MenuItem key={index} value={key}>{solvents[key]}</MenuItem>)});

    return (<Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth >
            <InputLabel id="solvent-selector-label-header">Solvent</InputLabel>
            <Select
                labelId="solvent-selector-label"
                id="solvent-selector-id"
                label="Solvent"
                onChange={props.onChange}
                value={props.selected ? props.selected : props.anyPossible ? 'any' : ''}
            >
                {listItem}
            </Select>
        </FormControl>
    </Box>
    );
};


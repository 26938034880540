import React from 'react';
import { useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';

import { fetchPost } from '../../services/GettingData';
import ErrorDialog from '../../components/ErrorDialog';
import Header from '../../components/HeaderComponent';
import ProgressControl from '../../components/ProgressControl';
import { Item } from '../../styles';
import '../../App.css';
import IRPeaks from '../../schemas/Spectrum/IRPeaks';

type IRSpectrumPreviewProps = {};

const SpectrumPreviewPage: React.FC<IRSpectrumPreviewProps> = (props) => {
    const location = useLocation() as { state: { irPeaks?: any } };
    const [irPeaks, setPeaks] = React.useState<IRPeaks>(location.state?.irPeaks);
    

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [showMessage, setShowMessage] = React.useState<string>('');
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);
    const spectrumSearchButtonRef = React.useRef<HTMLButtonElement>(null);
    const [tempPeaks, setTempPeaks] = React.useState(JSON.stringify(irPeaks.y_req));

    const closeErrorDialog = () => setDialogOpened(false);

    const showError = (errorMessage: string) => {
        setErrorMessage(errorMessage);
        setDialogOpened(true);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && spectrumSearchButtonRef.current) {
            spectrumSearchButtonRef.current.click();
        }
    };


    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [imageUrl]);


    React.useEffect(() => {
        (async () => {
            if (!irPeaks) {
                showError('No IR peaks data found.');
                return;
            }

            setLoading(true);
            try {
                const responsePreview = await fetchPost(
                    '/ir-spectrum/preview',
                    JSON.stringify(irPeaks),
                    true,
                    true,
                    undefined,
                    '',
                    3
                );

                const buffer = await responsePreview.arrayBuffer();
                const blob = new Blob([buffer], { type: 'image/png' });
                const objectUrl = URL.createObjectURL(blob);
                setImageUrl(objectUrl);

            } catch (error) {
                console.error('Error fetching spectrum preview:', error);
                showError('Failed to fetch spectrum preview.');
            } finally {
                setLoading(false);
            }
        })();

        // Cleanup function to revoke object URL when component unmounts
        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [irPeaks]);


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className='main-frame'>
                {/* Progress indicator */}
                <Grid item xs={12}>
                    <ProgressControl isLoading={isLoading} showTime={true} />
                </Grid>
                
                <Grid item xs={12}>
                    <div style={{ height: '1em' }}></div>
                </Grid>
                
                {/* Error dialog */}
                <ErrorDialog
                    isDialogOpened={isDialogOpened}
                    errorMessage={errorMessage}
                    warningMessage={showMessage}
                    onClose={closeErrorDialog}
                />
                
                {/* Header */}
                <Grid item xs={12}>
                    <Header title={'Infrared spectrum search'} helpAddress='help#htuirss' showLogin={true} />
                </Grid>

                <Grid item xs={12}>
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2,
                            mb: 2
                        }}
                    >
                        {imageUrl ? (
                            <Box 
                                sx={{
                                    width: '100%',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    p: 2
                                }}
                                className='mol-container'
                            >
                                <div className='graph-container'>
                                    <img 
                                        className='graph-qc' 
                                        src={imageUrl} 
                                        style={{ 
                                            maxWidth: '100%',
                                            height: 'auto',
                                            display: 'block'
                                        }} 
                                        alt="Spectrum Preview" 
                                    />
                                </div>
                            </Box>
                        ) : (
                            !isLoading && ( 
                            <Box 
                                sx={{
                                    width: '100%',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    p: 4,
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: 2
                                }}
                            >
                                <div style={{ 
                                    fontSize: '2rem', 
                                    fontWeight: 'bold',
                                    color: '#555'
                                }}>
                                    Preview is unavailable
                                </div>
                            </Box>
                        )
                        )}
                    </Box>
                </Grid>

                {/* Buttons container */}
                {!isLoading && (
                    <Grid item xs={12}>
                        <Grid 
                            container 
                            spacing={2}
                            sx={{
                                mt: 1,
                                mb: 3,
                                justifyContent: 'center'
                            }}
                        >
                            {/* Edit button - stack on small screens */}
                            <Grid item xs={12} sm={4} md={3} 
                                sx={{ 
                                    display: 'flex', 
                                    justifyContent: { xs: 'center', sm: 'flex-start' },
                                    pl: { sm: 4, md: 6 }
                                }}
                            >
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{ 
                                        maxWidth: { xs: '80%', sm: '90%', md: '80%' } 
                                    }}
                                    onClick={() => {
                                        console.log('Edit Spectrum clicked');
                                        // Add edit functionality here
                                    }}
                                >
                                    Edit Spectrum
                                </Button>
                            </Grid>

                            {/* Empty middle area - hidden on mobile */}
                            <Grid item xs={0} sm={4} md={6} sx={{ display: { xs: 'none', sm: 'block' }}}>
                                <Box sx={{ 
                                    textAlign: 'center', 
                                    p: 2,
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 1,
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                                }}>
                                    <div style={{ 
                                        fontSize: '1rem', 
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}>
                                        Adjust Spectrum Parameters
                                    </div>
                                    
                                    {/* Intensity threshold slider */}
                                    {<Box sx={{ mb: 2 }}>
                                        <label 
                                            htmlFor="intensity-threshold"
                                            style={{ display: 'block', marginBottom: '5px', fontSize: '0.9rem' }}
                                        >
                                            Intensity Threshold:
                                        </label>
                                        <input
                                            id="intensity-threshold"
                                            type="text"
                                            value={tempPeaks}
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                setTempPeaks(e.target.value);
                                            }}
                                        />
                                    </Box>
                                    }
                                    
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{ mt: 1 }}
                                        onClick={() => {
                                            // Refresh the spectrum preview with new parameters
                                            (async () => {
                                                setLoading(true);
                                                try {



                                                    setPeaks({
                                                        ...irPeaks,
                                                        y_req: JSON.parse(tempPeaks)
                                                    }
                                                        );
    

                                             
                                                } catch (error) {
                                                    console.error('Error fetching spectrum preview:', error);
                                                    showError('Failed to fetch spectrum preview.');
                                                } finally {
                                                    setLoading(false);
                                                }
                                            })();
                                        }}
                                    >
                                        Apply Changes
                                    </Button>
                                </Box>
                            </Grid>

                            {/* Search button */}
                            <Grid item xs={12} sm={4} md={3} 
                                sx={{ 
                                    display: 'flex', 
                                    justifyContent: { xs: 'center', sm: 'flex-end' },
                                    pr: { sm: 4, md: 6 },
                                    mt: { xs: 2, sm: 0 }
                                }}
                            >
                                <Tooltip arrow title='Search IR spectrum'>
                                    <Button
                                        ref={spectrumSearchButtonRef}
                                        color="success"
                                        variant="contained"
                                        fullWidth
                                        sx={{ 
                                            maxWidth: { xs: '80%', sm: '90%', md: '80%' } 
                                        }}
                                        onClick={() => {
                                            (props as any).history.push({
                                                pathname: '/ir-spectrum-result',
                                                state: { irPeaks: irPeaks },
                                            });
                                        }}
                                    >
                                        Spectrum Search
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default SpectrumPreviewPage;
import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { TextField, Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Item, Item2 } from '../../styles';
import DropPanel from '../../components/DropPanel';
import { getPreview } from '../../services/Spectrum';
import { SpectrumSearchMode, spectrumSearchModes } from '../../schemas/Spectrum/SpectrumSearchMode';

import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import ButtonWithHelp from '../../components/ButtonWithHelp';
import RefreshError from '../../schemas/Exception/RefreshError';
import { SolventControl } from '../../components/SolventControl';
import { fetchGet, fetchPost } from '../../services/GettingData';
import HelpIconWithHint from '../../components/HelpIconWithHint';

import SpectrumTypeControl from '../../components/SpectrumTypeControl';
import { SearchDirection } from '../../schemas/Spectrum/SearchDirection';
import ISpectrumPreviewModel from '../../schemas/Spectrum/ISpectrumPreviewModel';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';

import '../../App.css';

type SpectrumSearchProps = {
    spectrumPreview: ISpectrumPreviewModel
};


type SpectrumSearchState = {
    isMoleculeInContainer: boolean,
    files: File[],
    isInPreviewState: boolean,
    isInProgress: boolean,
    spectrumSearch: ISpectrumPreviewModel,
    showMessage: string,
    isDialogOpened: boolean,
    errorMessage: string,
};







export const  SpectrumSearchPage: React.FC<SpectrumSearchProps> = (props) => {

    const [isInProgress, setInProgress] = React.useState<boolean>(false);
    const [isInPreviewState, setInPreviewState] = React.useState<boolean>(false);
    
    const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);

    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [showMessage, setShowMessage] = React.useState<string>('');
    const [isMoleculeInContainer, setMoleculeInContainer] = React.useState<boolean>(false);
    const spectrumSearchButtonRef = React.useRef<HTMLButtonElement>(null);
    const [files, setFiles] = React.useState([]);

    const [spectrumSearch, setSpectrumSearch ]= React.useState<ISpectrumPreviewModel>({
        peaks: (props as any).location.state?.spectrumPreview?.searchDirection === SearchDirection.ByPeaks ?
            (props as any).location.state?.spectrumPreview?.peaks.sort((a: number, b: number) => a - b) : [],
        spectrum_type: (props as any).location.state?.spectrumPreview?.spectrum_type,
        solvent: undefined,
        is_complete: false,
        graph: '',
        spectrumString: (props as any).location.state?.spectrumPreview?.searchDirection === SearchDirection.BySpectrumString ?
            (props as any).location.state?.spectrumPreview?.spectrumString : '',
        structure: '',
        spectrumSearchMode: (props as any).location.state?.spectrumPreview?.spectrumSearchMode
            ? (props as any).location.state?.spectrumPreview?.spectrumSearchMode : SpectrumSearchMode.REACTION_MIXTURE_MODE,
    });

    const removeAt = (array: number[], atIndex: number) => {
        array.splice(atIndex, 1);
        return array;
    }



    const closeErrorDialog = () => setDialogOpened(false);


    const showError = (errorMessage: string) => {
        setErrorMessage(errorMessage);
        setDialogOpened(true);
    }


    const deletePeak = (id: number) => {
        console.log('delete peak with id', id);
        setSpectrumSearch((prev: any) => ({
            ...prev,
            'peaks': removeAt(prev.peaks, id)
        }));
    }



    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            hide: true
        },
        {
            field: 'shift',
            headerName: 'Shift',
            type: 'number',
            width: 150,
            sortable: false,
            editable: !spectrumSearch?.is_complete,
            valueFormatter: ({ value }) => String(value).replace(',', '.')
        },
        {
            field: 'actions',
            type: 'actions',
            width: 200,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                const onDelClick = (e: any) => {
                    e.stopPropagation();
                    deletePeak(Number(params.id));
                }
                return <div style={{width:'2em', height:'1em'}}
                    onClick={onDelClick}>X</div>;
            }
        },
    ];

    const pngString = encodeURIComponent(spectrumSearch.graph ? spectrumSearch.graph : '');
    const dataUri = `data:image/png;base64,${pngString}`;


    const handleChange = (files: File[]) => {
        setFiles(files);
    };

    
    const reduceCalculated = (prev: ISpectrumPreviewModel): ISpectrumPreviewModel => {
        let peaks = prev.searchDirection === SearchDirection.ByPeaks ? prev.peaks : [];
        let spectrumString = prev.searchDirection === SearchDirection.BySpectrumString ? prev.spectrumString : '';
        return { ...prev, peaks: peaks, spectrumString: spectrumString };
    }


    const editSpectrum = () => {
        setSpectrumSearch(prev => ({
                ...reduceCalculated(prev),
                is_complete: false
        }));
    }

    const submitSearch = async () => {
        let response: any;
        try {
            if (files.length > 0) {
                response = await searchByZip() as ISpectrumPreviewModel;
                response.searchDirection = SearchDirection.ByZipFile;
            }
            else
                if (spectrumSearch.spectrumString) {
                    response = await searchByString() as ISpectrumPreviewModel;
                    response.searchDirection = SearchDirection.BySpectrumString;
                    //startStructSearch();
                }
                else
                    if (spectrumSearch.peaks.length > 0) {
                        if (!spectrumSearch.spectrum_type) {
                            setDialogOpened(true);
                            setShowMessage('Please, select Spectrum Type' );
                            return;
                        }
                        response = await searchByPeaks() as ISpectrumPreviewModel;
                        response.searchDirection = SearchDirection.ByPeaks;
                    }

            if (response) {
                response.is_complete = true;
                response.spectrumSearchMode = SpectrumSearchMode.REACTION_MIXTURE_MODE;
                setSpectrumSearch(prev=>({...response as any,
                    spectrumString: prev.spectrumString,
                    peaks: response.peaks.sort((a: number, b: number) => a - b),
                
                }))
            }
            else {
                setDialogOpened(true);
                setShowMessage('Please, input some data');

            }
        }
        catch (e: any) {
            if (e instanceof RefreshError) {
                showError(e.message);
                (props as any).history.push({ pathname: '/login', state: { backTo: '/spectrum-search', welcomeMessage: true } })
            }
            if (e instanceof NotAuthorizedError) {
                (props as any).history.push({ pathname: '/login', state: { backTo: '/spectrum-search', welcomeMessage: true } })
            } else {
                console.error(e.toString());
                showError('Internal Server Error');
            }
        }
    }



    const helperSpectraStringExamples = () => {
        const helperTexts = [
            {
                id: 1,
                value: "13C NMR (100 MHz, CDCl3) δ 19.5, 32.5, 46.5, 120.5, 126.3, 126.4, 126.8, 134.2, 149.1, 159.5, 161.0"
            }];

        return helperTexts.map((text) => (
            <span style={{ cursor: 'pointer' }} onClick={() => {
                setSpectrumSearch(prev => ({
                        ...prev,
                        spectrumString: text.value,
                }))
            }} key={text.id} data-id={text.id} className="helper-text">
                {'ex. ' + text.value}
            </span>
        ));
    }



    const onCellEditCommit = (cellData: any) => {
        if (cellData.field === 'shift') {
            let peaks = spectrumSearch.peaks;
            peaks[cellData.id] = Number(cellData.value);
            setSpectrumSearch(prev => ({
                    ...prev,
                    'peaks': peaks,
            }));
        }

        if (cellData.field === 'int') {
        }
    }

    
    const rows = [];
    for (let i = 0; i < spectrumSearch.peaks.length; i++) {
        rows.push({ id: i, shift: spectrumSearch.peaks[i] });
    }

    const reactionMixtureModes = Object.keys(spectrumSearchModes()).map((key, index) => { return (<MenuItem key={index} value={key}>{key.replace(/_/g, ' ')}</MenuItem>) });

    const changeSpectrTypeHandler = (e: SelectChangeEvent) => {
        setSpectrumSearch((prev: ISpectrumPreviewModel) => ({ ...prev, 'spectrum_type': e.target.value }))
    }

    const searchByZip = async (): Promise<ISpectrumPreviewModel> => {
        const defaultErrorMessage = 'Incorrect uploaded file';
        const dataTypeDx = 'multipart/form-data';
        const dataTypeZip = 'application/x-zip-compressed';

        let endPoint = '/spectrum/preview/upload';
        let file = files[0];
        let body = new FormData()
        let isDx = file.name.toLowerCase().endsWith('.dx');
        if (isDx) {
            let blobWithNewType = new Blob([file], { type: "chemical/x-jcamp-dx" });
            body.append("file", blobWithNewType, file.name);
        }
        else
            body.append('file', file);
        const response = await fetchPost(endPoint, body, true, true, isDx ? dataTypeDx : dataTypeZip, defaultErrorMessage);
        return await response.json();
    }


    const searchByPeaks = async (): Promise<ISpectrumPreviewModel> => {
        const response = await getPreview({
            'spectrum_type': spectrumSearch.spectrum_type,
            "peaks": spectrumSearch.peaks,
            "solvent": spectrumSearch.solvent === '' ? undefined : spectrumSearch.solvent,
            "is_complete": false,
            "spectrumString": "",
            "structure": "",
        });
        return response;
    }

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                console.log('kd enter', spectrumSearch.is_complete);

                if (!spectrumSearch.is_complete)
                    submitSearch();
                else
                    spectrumSearchButtonRef.current.click();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [spectrumSearch.is_complete]);

    

    const searchByString = async (): Promise<ISpectrumPreviewModel> => {
        const response = await fetchGet('/spectrum/preview/string?spectrum_string=' + spectrumSearch.spectrumString);
        if (!response.ok)
            throw 'Incorrect spectrum string,\r\n' + response.statusText;
        return await response.json();
    }


    const addPeak = (): void => {
        setSpectrumSearch(prev => ({
                ...prev,
                'peaks': [...prev.peaks, 0],
        }));
    }


    const changeSolventHandler = (e: SelectChangeEvent) => {
        setSpectrumSearch((prev: ISpectrumPreviewModel) => ({...prev, 'solvent': (e.target.value === 'any' ? undefined : e.target.value) }));
    }


    return (
        <Grid container md={12} spacing={0} className='main-frame'>
            <Grid container md={12}>
                <Item2><div style={{ height: '2em' }}></div></Item2>
            </Grid>
            <ErrorDialog isDialogOpened={isDialogOpened}
                errorMessage={errorMessage}
                warningMessage={showMessage}
                onClose={closeErrorDialog} />
            <Grid xs={12}>
                <Header title={(props as any).location.state?.spectrumPreview?.spectrumSearchMode} helpAddress='help#htuss' showLogin={true} />
            </Grid>
            <Grid container xs={12} spacing={0} style={{ marginTop: '.5em' }}>
                <Grid xs={4}>
                </Grid>
                <Grid xs={4}>

                    {spectrumSearch.is_complete &&
                        <Box sx={{ minWidth: 120 }}>
                            <InputLabel id="spectrum-search-mode-selector-header">Spectrum Search Mode</InputLabel>
                            <Select style={{ minWidth: '20em' }}
                                labelId="spectrum-search-mode-label"
                                id="spectrum-search-mode"
                                label="Spectrum Search Mode"
                                value={spectrumSearch.spectrumSearchMode}
                                onChange={(e)=> {
                                    console.log('spectrumSearchMode switched to', e.target.value);
                                    setSpectrumSearch(prev => ({
                                            ...prev,
                                            spectrumSearchMode: e.target.value as string,
                                    }));
                                }}>
                                {reactionMixtureModes}
                            </Select>
                            <HelpIconWithHint title={''}
                                text={''}
                                showImage={true}
                                handleOpen={() => { }} />
                        </Box>
                    }

                </Grid>
                <Grid md={4} xs={12} sm={12} style={{ paddingLeft: '2em' }}>
                    <Item2 style={{ fontSize: '1em', textAlign: 'left' }}>
                        <div style={{ marginTop: '1em' }}>
                            <Grid container alignItems="center" justifyContent="center" >
                                <Grid xs={11}>
                                    <SpectrumTypeControl onChange={changeSpectrTypeHandler}
                                        disabled={spectrumSearch.is_complete}
                                        selected={spectrumSearch.spectrum_type} />
                                </Grid>
                                <Grid xs={1} style={{ verticalAlign: 'middle' }}>
                                    <HelpIconWithHint title={''}
                                        text={'You need this field ONLY if you insert peaks to the shift-intensity table. In this case choose spectrum type, solvent and add as much peaks as you want. Otherwise do not care about these fields (spectrum type, solvent)'}
                                        handleOpen={() => { }} />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '1em' }}>
                            <Grid container alignItems="center" justifyContent="center" >
                                <Grid xs={11}>

                                    <SolventControl anyPossible={true}
                                        onChange={changeSolventHandler}
                                        selected={spectrumSearch.solvent} />
                                </Grid>

                                <Grid xs={1} style={{ verticalAlign: 'middle' }}>
                                    <HelpIconWithHint title={''}
                                        text={'Here you can change solvent'}
                                        handleOpen={() => { }} />
                                </Grid>
                            </Grid>

                        </div>
                    </Item2>
                </Grid>
            </Grid>

            <Grid container md={12} spacing={0} style={{ marginTop: '2em' }}>
                <Grid container
                    style={{ marginBottom: '2em' }}
                    spacing={0}
                    md={8} xs={12}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">

                    {!spectrumSearch.is_complete &&
                        <Item style={{
                            width: '100%',
                            minHeight: '6em',
                            backgroundColor: '#d5f5f5',
                            textAlign: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            <DropPanel onChange={handleChange} 
                                       text={'Drag zip archive with spectrum folder here.'}
                                       warningOnly='Only normal quality spectra are will be correctly processed (S/N > 10/1, flat baseline).'
                                       warningSupport={'Bruker and Varian files are supported. 1r file should be in the Bruker file folder'}/>
                            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} className='mol-container'></div>
                        </Item>
                    }
                    {!spectrumSearch.is_complete &&
                        <Item style={{ width: '100%', marginTop: '1em' }}>
                            <div className='mol-container'>
                                <TextField className='spectrum-text'
                                    type="text"
                                    value={spectrumSearch.spectrumString}
                                    sx={{
                                        input: {
                                            "&::placeholder": {
                                                opacity: .5,
                                            },
                                        },
                                        label: { color: 'blue' }
                                    }}
                                    onChange={(c) => {
                                        setSpectrumSearch(prev => ({ ...prev, spectrumString: c.target.value }))
                                    }}
                                    helperText={helperSpectraStringExamples()}
                                    style={{ width: '90%' }}
                                    placeholder='Paste spectrum string here' />
                                <span style={{ position: 'relative', top: '.7em' }}>
                                    <HelpIconWithHint title={''}
                                        text={'Paste here the spectrum description like in the paper, e.g. ‘13C NMR (101 MHz, MeOD): δ 158.9, 131.8, 115.4, 112.1, 54.5;'}
                                        handleOpen={() => { }} />
                                </span>

                            </div>
                        </Item>
                    }
                    {spectrumSearch.is_complete && spectrumSearch.graph ?
                        <Grid xs={12}
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center">
                            <Item style={{ width: '100%', marginRight: '.5em' }} >
                                <div className='graph-container' style={{ paddingBottom: '0px', paddingTop: '2px' }}>
                                    <img className='graph-qc' src={dataUri} style={{ maxWidth: '100%' }} />
                                </div>
                            </Item>
                        </Grid> : ''}

                </Grid>

                <Grid md={4} xs={12} style={{ marginTop: '0px' }} className='pamd1' spacing={1}>
                    <Item style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
                        <Box sx={{ height: 300, width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                disableColumnMenu
                                onCellEditCommit={onCellEditCommit}
                            />
                        </Box>
                        <Tooltip arrow title='Manual insertion of peaks and intensity'>
                            <Button onClick={() => addPeak()}
                                variant="contained"
                                style={{ marginBottom: '1em', marginTop: '1em' }}>Add Peak</Button>
                        </Tooltip>


                    </Item>
                </Grid>
            </Grid>

            <Grid container md={12} spacing={2} style={{ marginTop: '0em' }}>
            </Grid>

            <Grid container md={12} spacing={0} style={{ marginTop: '2em' }}>
                <Grid item md={4}></Grid>
                <Grid item md={4} xs={12} style={{ minWidth: '220px' }}>
                    <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
                        {spectrumSearch.is_complete &&
                            <ButtonWithHelp variant='contained'
                                tooltip='Serious modifications of the request'
                                title='Edit spectrum'
                                text='Press here if you want to modify something except for solvent or add/remove peak'
                                onClick={editSpectrum} disabled={false} />
                        }</Item2>
                </Grid>
                <Grid md={4} xs={12} className='pamd1'>
                    <Item2 style={{ fontSize: '2em', paddingTop: '0px', paddingBottom: '0px' }}>
                        {!spectrumSearch.is_complete ?
                            <>
                                <Tooltip arrow title='Process the inserted data'>
                                    <Button onClick={() => submitSearch()} variant="contained">Submit</Button>
                                </Tooltip>
                                <HelpIconWithHint title={''}
                                    text={'After insertion of the information press here to submit it to the web-site'}
                                    handleOpen={() => { }} />
                            </>
                            :
                            <div>
                                <Link className='MuiButton' to={{
                                    pathname: `/spectrum-search-result`,
                                    state: { spectrumPreview: spectrumSearch }
                                }}>
                                    <Tooltip arrow title='Search these peaks'>
                                        <Button ref={spectrumSearchButtonRef} 
                                                color="success" variant="contained">Spectrum Search</Button>
                                    </Tooltip>
                                </Link>
                                <HelpIconWithHint title={''}
                                    text={'If everything is ok, press here to search for this spectrum'}
                                    handleOpen={() => { }} />
                            </div>
                        }
                    </Item2>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default SpectrumSearchPage;
import React from 'react';
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import LinearProgress from '@mui/material/LinearProgress';
import { Tooltip, Dialog } from '@mui/material';


import AppSettings from '../../API/appSettings';
import { getSession } from '../../services/Login';
import translate from '../../services/Translation';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import MoleculeView from '../../components/MoleculeView';
import ProgressControl from '../../components/ProgressControl';
import FilterComponent from '../../components/Sockets/FilterComponent';
import TimeoutError from '../../schemas/Exception/TimeoutError';
import RefreshError from '../../schemas/Exception/RefreshError';
import HelpIconWithHint from '../../components/HelpIconWithHint';
import '../../App.css';
import { Item, Item2, Found } from '../../styles';
import WebSocketConnection from '../Sockets/WebSocketConnection';
import Config from '../../config.json';
import Accept from '../../schemas/Compound/Accept';
import { SearchStatus } from '../../schemas/Compound/SearchStatus';
import IGetResponse from '../../schemas/Compound/IGetResponse';
import IProgressInfo from '../../schemas/Compound/IProgressInfo';
import ISubSearchFilter from '../../schemas/Compound/ISubSearchFilter';
import IFinishInfo from '../../schemas/Compound/IFinishInfo';
import MoleculeEditor from './MoleculeEditor';
import { Socket } from 'socket.io-client';
import { molToSvg } from '../../services/NameToMolecule';



type SubSearchProps = RouteComponentProps & {
    subSearchFilterModel: ISubSearchFilter
};

  
export const SubSearchPage: React.FC<SubSearchProps> = (props) => {
  const activeSubscriptions = new Set<string>();

  const getSVG = (): string => (props as any).location?.state?.svgContent;

  const getMolecule = (): string => {
    if ((props as any).location?.state?.moleculeString)
      return (props as any).location?.state?.moleculeString;
    return '';}

  
  const [extraMoleculeSvg, setExtraMoleculeSvg] = React.useState<string | undefined>(undefined);
  const [extraMolecule, setExtraMolecule] = React.useState<string>('');
  const [molecule, setMolecule] = React.useState<string>(getMolecule());
  const [moleculeSvg, setMoleculeSvg] = React.useState<string>(getSVG());
  
  const state = props.location.state as { subSearchFilterModel: ISubSearchFilter };

  const loadFilterSettings = (): ISubSearchFilter => {
    let filters = {
      structure: molecule,
      compound_props: state?.subSearchFilterModel?.compound_props || [],
      show_radicals: state?.subSearchFilterModel?.show_radicals || false,
      iterate_tautomers: state?.subSearchFilterModel?.iterate_tautomers || false,
      optional_structures: state?.subSearchFilterModel?.optional_structures || [extraMolecule],
      nmr_types: state?.subSearchFilterModel?.nmr_types || ['H_NMR'],
    }
    return filters;
  }

  const [connection, setConnection] = React.useState<Socket | undefined>(undefined);
  
  const [search, setSearch] = React.useState<string>();
  const searchRef = React.useRef<string | undefined>(search);
  const [searchStatus, setSearchStatus] = React.useState<SearchStatus>(SearchStatus.Started);
  const [requestedPage, setRequestedPage] = React.useState<number | undefined>(undefined);
  const requestedPageRef = React.useRef<number | undefined>(requestedPage);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<IGetResponse>();
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [subSearchFilter, setSubSearchFilter] = React.useState<ISubSearchFilter>(loadFilterSettings());
  const [inProgressFound, setInProgressFound] = React.useState<number>(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditExtraMolecule, setEditExtraMolecule] = React.useState(false);
                          
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const history = useHistory();

  const onAccept = (packet: Accept, connection: Socket) => {
    setSearch(packet.id);
    setConnection(connection);
    connection.on('/search/finish', (packet) => onFinish(packet, packet.id));
    connection.on('/search/progress', (packet) => onProgress(packet));
    connection.on('/search/get', (data) => onGet(data, packet.id));
    connection.on('/error', (data) => onError(data));
    setRequestedPage(undefined);
  }

  React.useEffect(() => {
    searchRef.current = search;
  }, [search]);


  React.useEffect(() => {
    const fetchExtraMoleculeSvg = async () => {
    
      if (state?.subSearchFilterModel?.optional_structures.length > 0 && state?.subSearchFilterModel?.optional_structures[0]) {
        setExtraMolecule(state?.subSearchFilterModel?.optional_structures[0]);
        setExtraMoleculeSvg(await molToSvg(state?.subSearchFilterModel?.optional_structures[0]));
      }
    };
    fetchExtraMoleculeSvg();
  }, [state?.subSearchFilterModel?.optional_structures]);



  React.useEffect(() => {
    requestedPageRef.current = requestedPage;
  }, [requestedPage]);


  const onError = (packet: any) => {
    console.log('/error', packet.error);
    showError(packet.error);
    setLoading(false);
  }


  const onFinish = (packet: IFinishInfo, searchId: string) => {
    if (packet.id === searchId) {
      setSearchStatus(SearchStatus.Finished);
      connection?.off('/search/finish');
    }
  }


  React.useEffect(() => {
    if (searchStatus === SearchStatus.Finished && requestedPage !== currentPage) {
      get(currentPage);
    }
    if (searchStatus === SearchStatus.InProgress) {
      setCurrentPage(1);
      get(1);
    }

  }, [searchStatus, currentPage]);


  const onProgress = (progress: IProgressInfo) => {
    if (progress.search_id === searchRef.current) {
      if (searchStatus !== SearchStatus.InProgress) {
        setInProgressFound(progress.progress.n_items);
        setSearchStatus(SearchStatus.InProgress);
      }
    }
  }


  const startStructSearch = async (): Promise<boolean> => {
    setSearchStatus(SearchStatus.Started);
    WebSocketConnection.start({
        "type": "sub",
        "params": subSearchFilter
      }, onAccept, onError, 'sub-search' );
    return true;
  }


  const onGet = (packet: IGetResponse, searchId: string) => {
    if (packet.id === searchId) {
      setCompoundSearchResponse(packet);
      setLoading(false);
      connection?.off('/search/get');
    }
  }


  const get = (currentPage: number) => {
    if (search && connection) {
      connection.on('/search/get', (data) => onGet(data, search));
      setLoading(true);
      setRequestedPage(currentPage);
      connection.emit("/search/get", {
        "id": search,
        "page": currentPage, 
        'per_page': Config.itemsPerPage,
      });
    }
  }



 

  // const endPoint = '/compound/search/sub?page=' + page + '&size=' + compoundSearchResponse?.size;
  // try {
  //   const response = await fetchPost(endPoint, JSON.stringify(subSearchFilterModel), true, true);
  //   if (!response.ok) {
  //     showError(response.statusText);
  //     return { items: [], total: 0, page: 0, size: 0 }
  //   }
  //   return await response.json();
  // }
  // catch (e: any) {
  //   setIsLoading(false);
  //   if (e instanceof NotAuthorizedError)
  //     (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
  //   if (e instanceof ExpiredAccountError)
  //     (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
  //   if (e instanceof RefreshError) {
  //     showError(e.message);
  //     (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
  //   }
  //   if (e instanceof TimeoutError) {
  //     console.error(e.message);
  //     showError(e.message);
  //   }
  //   else
  //     showError(e.toString());
  //   return { items: [], total: 0, page: 0, size: 0 }
  // }



  const refreshSub = async () => {
    startStructSearch();
  }


  React.useEffect(() => {
    refreshSub();
  }, []);

      

  const pageChangeHandle = async (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
    setLoading(true);
  }


  const closeErrorDialog = () => {  
    setErrorDialogOpened(false);
  }


  const newSearch = (): void => {
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN);
    history.push('/compound-search/' + AppSettings.MAIN);
  }


  const applyFilter = async (): Promise<void> => {
    setLoading(true);
    await refreshSub();
  }


  const filterChanged = (filterModel: ISubSearchFilter) => {
    setSubSearchFilter(prev =>
    ({
      ...filterModel }
    ))
  }


  const obtainTotal = (compoundSearchResponse: IGetResponse | undefined): string => {
    if (searchStatus === SearchStatus.Started)
      return '';
    if (searchStatus === SearchStatus.InProgress && inProgressFound > 0)
      return 'Found: ' + inProgressFound + ' molecules';
    if (searchStatus === SearchStatus.Finished && compoundSearchResponse && compoundSearchResponse.total < inProgressFound)
      setCompoundSearchResponse({...compoundSearchResponse, total: inProgressFound});
    if (searchStatus === SearchStatus.Finished && compoundSearchResponse && compoundSearchResponse.total >= inProgressFound)
      return 'Found: ' + (compoundSearchResponse.total > 0 ? compoundSearchResponse.total : 0) + ' molecules';
       
    return ''
  }


  const onUpdateMolecule = (moleculeStructure: string, moleculeSVG: string) => {
    if (isEditExtraMolecule) {
      setExtraMolecule(moleculeStructure);
      setExtraMoleculeSvg(moleculeSVG);
      setSubSearchFilter(prev =>
      ({
        ...prev,
        optional_structures:[moleculeStructure]
      }));

    }
    else {
      setMolecule(moleculeStructure);
      setMoleculeSvg(moleculeSVG);
      setSubSearchFilter(prev =>
        ({ ...prev, structure: moleculeStructure }))
    }
  }

  return (<Grid container spacing={0} className='main-frame'>
    <ProgressControl isLoading={isLoading} showTime={true} />
    <Grid xs={12}>
      <Item2><div style={{ height: '2em' }}></div></Item2>
      { }
    </Grid>
    <ErrorDialog isDialogOpened={isErrorDialogOpened}
      errorMessage={errorMessage}
      onClose={closeErrorDialog} />

    <Header title='Substructure search' showLogin={getSession() !== undefined} helpAddress='help#wdaafpc' />
    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="lg" fullWidth>
        <MoleculeEditor updateMolecule={onUpdateMolecule}
          editorLoaded={() => { setLoading(false); }}
          closeWindow={() => setIsModalOpen(false)}
          previousMolecule={isEditExtraMolecule ? extraMolecule : molecule} />
      </Dialog>

    <Grid container spacing={4} style={{ minWidth: "250px", marginTop: '4em' }} md={12}>
      <Grid>
      </Grid>
      <Grid style={{ marginLeft: '1em', marginRight: '1em', display: "flex", alignItems: "center" }}>
        <div></div>
        <Item2>
          <div style={{ marginBottom: '1em' }}>
            <Tooltip arrow title='start new search'>
              <Button onClick={() => newSearch()} variant="contained">New Search</Button>
            </Tooltip>
            <HelpIconWithHint title={''}
              text={'Drop results and draw new query'}
              handleOpen={() => { }} />
          </div>
          <div
            onClick={() => {
              setEditExtraMolecule(false);
              setLoading(true);
              setIsModalOpen(true);
            }}
            onDoubleClick={() => {
              setEditExtraMolecule(true);
              setLoading(true);
              setIsModalOpen(true);
            }}
          >
          </div>
          
        </Item2>
      </Grid>
      <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em' }}>
        <div><span className='box-title'>Substructure search</span></div>
        <Item style={{ width: "250px", marginTop: '12px',  cursor: 'pointer' }}>
        
        <div onClick={()=>{
          setEditExtraMolecule(false);
          setLoading(true);
          setIsModalOpen(true);
          }}>
        
          <MoleculeView link={''}
            svgContent={moleculeSvg}
            isMoleculeInContainer={true}
            moleculeString={(props as any).location?.state?.moleculeString} />
            </div>
        </Item>
      </Grid>

      <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em', }}>
        <div>Extra filter</div>
        <Item style={{ width: "250px", marginTop: '12px' }}
         onClick={() => { 
          setEditExtraMolecule(true);
          //setLoading(true);
          setIsModalOpen(true);
         }}
        >
          {<MoleculeView
            svgContent={extraMoleculeSvg}
            tooltip={'Open ketcher to draw substructure to filter results'}
            isMoleculeInContainer={extraMolecule !== ''} />}
        </Item>
      </Grid>

      <Grid>
        <div style={{ marginBottom: '12px' }}><span className='box-title'>Filter</span></div>
        {<FilterComponent
          onChanged={filterChanged}
          subSearchFilter={subSearchFilter} />}
        <div style={{ marginBottom: '1em', marginTop: '2em', textAlign: 'center' }}>
          <Tooltip arrow title='Apply selected filters to your results'>
            <Button onClick={applyFilter} variant="contained">Apply Filter</Button>
          </Tooltip>
          <HelpIconWithHint title={''}
            text={'Apply selected filters to the current search results'}
            handleOpen={() => { }} />
        </div>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginBottom: '1em' }}>
        </div>
      </Grid>
    </Grid>

    <Grid md={12} xs={12}>
      {!isLoading &&
        <Grid md={12} xs={12} style={Found}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {obtainTotal(compoundSearchResponse)}
            </div>
            {searchStatus === SearchStatus.InProgress && (
              <div style={{ marginLeft: '1em', flexGrow: 1, maxWidth: '3em' }}>
                <LinearProgress />
              </div>
            )}
          </div>
        </Grid>
      }
    </Grid>

    {compoundSearchResponse?.items &&
      compoundSearchResponse.items.map((compound, index) => (
        <Grid container sm={12} md={10} style={{ paddingTop: '0.7em' }} key={index} className='compound-feed'>
          <Grid sm={12} md={3} >
            <MoleculeView link={''} moleculeId={compound.id}
              svgContent={compound.svg}
              isMoleculeInContainer={true}
              inline={true}
              moleculeString={compound.molecule}
            />
          </Grid>
          <Grid container sm={12} md={9}>
            <Grid sm={4} xs={12}>
              {compound.total_publications ? <div>Publications found: {compound.total_publications}</div> : null}
            </Grid>
            <Grid sm={4} xs={12}>
              {
                Object.entries(compound.properties).map(([key, value]) => {
                  return (value > 0 && <div key={key}>{translate(key)} found: {value}</div>)
                })
              }
            </Grid>
            <Grid sm={4} xs={12}>
              {compound.associated_spectra && Object.entries(compound.associated_spectra).map((spectra, count) => (
                <div key={spectra[0]}>{translate(spectra[0])} spectra found: {spectra[1]}</div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))

    }

    <Grid md={12} xs={12} className='pagination-line' style={{ display: "inline" }} >
      {!isLoading && (searchStatus === SearchStatus.Finished || searchStatus === SearchStatus.InProgress) &&
        <Grid style={{ width: '100%' }}>
          {compoundSearchResponse && compoundSearchResponse?.total > 0 &&
            <Pagination count={Math.ceil(compoundSearchResponse?.total / compoundSearchResponse?.pagination.per_page)}
              page={compoundSearchResponse?.pagination.page} onChange={pageChangeHandle} />}
        </Grid>
      }
    </Grid>
  </Grid>);
}
export default SubSearchPage;

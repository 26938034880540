import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';

import { Item2 } from '../../styles';
import IUser from '../../schemas/Login/IUser';
import { AccountType } from './AccountType';
import ISession from '../../schemas/Login/ISession';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import ProgressControl from '../../components/ProgressControl';
import { getSession, logout, updSession } from '../../services/Login';
import NotAuthorizedError from "../../schemas/Exception/NotAuthorizedError";
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog'; 
import { fetchGet, fetchPut, fetchDelete } from '../../services/GettingData';
import Config from '../../config.json';
 

export const PersonalInfoPage: React.FC = (props) => {

  const [email, setEmail] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [institution, setInstitution] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [accountType, setAccountType] = useState<string>(AccountType.Trial);
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [active, setActive] = useState<boolean>(true);
  const [isSubmissionOpen, setSubmissionOpen] = useState<boolean>(false);
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [session, setSession] = useState<ISession | undefined>(undefined);
  const [sessionActive, makeSessionActive] = useState<boolean>(false);
  const [isConfirmationDialogOpened, setConfirmationDialogOpened] = React.useState<boolean>(false);

  
  const history = useHistory();

  React.useEffect(() => {
    if ((props as any).location?.state?.expired)
      showError('Account expired');
  }, []);


  const submitUpdate = () => {
    const fetchData = async () => {
      setLoading(true);
      const url = '/user/me';
      const response = await fetchPut(url,
        JSON.stringify({
          'firstname': firstName,
          'lastname': lastName,
          'institution': institution,
          'position': position,
        }), false, true);
      setLoading(false);
      if (response.ok) {
        updSession(firstName);
        history.push('/');
      }
      else {
        const json = await response.json();
        if (json.detail)
          showError(json.detail);
        else
          showError('Update failed');
      }
    }
    fetchData()
      .catch(console.error);
  }


  const removeAccountConfirmation = () => {
    setConfirmationDialogOpened(true);
  }


  const removeAccount = () => {
    const fetchData = async () => {
      const url = '/user/me';
      const response = await fetchDelete(url, false, true);
      if (response.ok) 
        logoutSession();
      else {
        const json = await response.json();
        if (json.detail)
          showError(json.detail);
        else
          showError('Removal failed');
      }
    }
    fetchData()
      .catch(console.error);
  }


  const logoutSession = () => {
    logout();
    history.push('/');
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
  }


  const onCloseErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const onCloseConfirmationDialog = () => {
    setConfirmationDialogOpened(false);
  }


  const enableControls = () => {
    makeSessionActive(true);
  }


  const getUser = () => {
    const fetchData = async () => {

      try {
        setLoading(true);
        const response = await fetchGet('/user/me', false, true);
        if (response?.ok) {
          let user = (await response.json()) as IUser;
          setAccountType(user.account_type as string);
          setFirstName(user.firstname as string);
          setLastName(user.lastname as string);
          setEmail(user.email as string);
          setInstitution(user.institution as string);
          setPosition(user.position as string);
          setLoading(false);
          setActive(user.is_active ? user.is_active : false);
          setDaysLeft(user.days_left ? user.days_left :  0);
          enableControls();
        }
        else {
          setLoading(false);

          let msgError = 'Getting User data failed, please try to logoff and re-login';
          if (response?.status !== 500) {
            const json = await response?.json();
            if (json?.detail)
              msgError = json?.detail
          }
          showError(msgError);
          enableControls();
          return undefined;
       }
      }
      catch (e: any) {
        console.log('getUser error', e);
        if (e instanceof NotAuthorizedError) {
          history.push({ pathname: '/login', state: { backTo: '/personal', welcomeMessage: false } })
        } else {
          setLoading(false);
          showError(e.toString());
        }
      }
    }
    fetchData()
      .catch(console.error);
  }



  React.useEffect(() => {
    let sessionT = getSession();
    if (sessionT) {
      setSession(sessionT);
      getUser();
    }
  }, []);


  React.useEffect(() => {
    setSubmissionOpen(!!email && email.indexOf('@') > -1
      && !!position
      && !!institution
      && !!lastName
      && !!firstName);
  }, [email, lastName, firstName, position, institution]);


  return (
    <Grid container className='main-frame' spacing={0}>
      <Grid container md={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <ConfirmationDialog dialogText={'Do you want to remove your account?'} 
                          doAfter={removeAccount} 
                          isDialogOpened={isConfirmationDialogOpened}
                          onClose={onCloseConfirmationDialog} />

      <ErrorDialog isDialogOpened={isErrorDialogOpened}
        errorMessage={errorMessage}
        warningMessage={''}
        onClose={onCloseErrorDialog} />

      <Header title='Personal Information' showLogin={false} />
      <Grid style={{ height: '2em' }} xs={12}></Grid>
      {isLoading ? <ProgressControl isLoading={isLoading} /> :
        <>
          <Grid container xs={12} style={{ marginTop: '2em' }}>
            {accountType === AccountType.Trial ?
              <>
                <Grid xs={12} style={{ display: 'flex', justifyContent: 'right', marginBottom: '1em' }}>Trial (left {daysLeft} days)</Grid>
                <Grid xs={12} style={{ display: 'flex', justifyContent: 'right', marginBottom: '1em' }}>{active ? 'Account: Active' : 'Account: Not Active' }</Grid>
                
              </>
              :
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'right', marginBottom: '1em' }}>Full account</Grid>
            }
            {accountType === AccountType.Trial && <>
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'right' }}>To upgrade account please write us:</Grid>
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'right' }}>{Config.contact}</Grid>
            </>
            }
          </Grid>

          <Grid xs={12} style={{ marginTop: '2em' }}>
            <TextField type="text"
              value={firstName}
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: .5,
                  },
                },
                label: { color: 'blue' }
              }}
              onChange={(c) => {
                setFirstName(c.target.value)
              }}
              helperText=""
              style={{ width: '100%', marginTop: '1em' }}
              placeholder='First Name' />
          </Grid>
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField type="text"
              value={lastName}
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: .5,
                  },
                },
                label: { color: 'blue' }
              }}
              onChange={(c) => {
                setLastName(c.target.value)
              }}
              helperText=""
              style={{ width: '100%', marginTop: '1em' }}
              placeholder='Last Name' />
          </Grid>
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField type="text"
              value={email}
              disabled={!!session}
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: .5,
                  },
                },
                label: { color: 'blue' }
              }}
              onChange={(c) => {
                setEmail(c.target.value)
              }}
              helperText=""
              style={{ width: '100%', marginTop: '1em' }}
              placeholder='email' />
          </Grid>

          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField className='reg-line'
              type="text"
              value={institution}
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: .5,
                  },
                },
                label: { color: 'blue' }
              }}
              onChange={(c) => {
                setInstitution(c.target.value)
              }}
              helperText=""
              style={{ width: '100%', marginTop: '1em' }}
              placeholder='Institution' />
          </Grid>

          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField type="text"
              value={position}
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: .5,
                  },
                },
                label: { color: 'blue' }
              }}
              onChange={(c) => {
                setPosition(c.target.value)
              }}
              style={{ width: '100%', marginTop: '1em' }}
              placeholder='Position' />
          </Grid>

          <Grid xs={12} style={{ padding: '.5em', marginTop: '1em', textAlign: 'right' }}>
            For any issues: {Config.contact}
          </Grid>
          <Grid xs={12} style={{ padding: '.5em', textAlign: 'right' }}>
            <a href='/privacy-policy' target='_blank'>Privacy policy</a>
          </Grid>


          <Grid container xs={12} style={{ marginTop: '3em' }}>
            <Grid md={1} sm={12}></Grid>
            <Grid md={2} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom:'1em' }}>
              <Button disabled={!isSubmissionOpen} onClick={() => submitUpdate()} variant="contained">Submit</Button>
            </Grid>
            <Grid md={2} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom:'1em' }}>
              <Button disabled={!sessionActive} variant='outlined' onClick={() => { 
                history.push({ pathname: '/restore', state: {
                  email: email
              } } ) }} >Change Password</Button>
            </Grid>
            <Grid md={2} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom:'1em' }}>
              <Button disabled={false} variant='outlined' onClick={() => { 
                history.push({ pathname: '/search-history', state: {
              } } ) }} >Search History</Button>
            </Grid>
            <Grid md={2} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom:'1em' }}>
              <Button disabled={!sessionActive} variant='outlined' onClick={() => logoutSession()}>Logout</Button>
            </Grid>
            <Grid md={2} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom:'1em' }}>
              <Button disabled={!sessionActive} variant='outlined' onClick={() => removeAccountConfirmation()} >Remove Account</Button>
            </Grid>
          </Grid>
        </>}

    </Grid>);
}

export default PersonalInfoPage;


import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import TabPanel from '../../components/TabPanel';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Socket } from "socket.io-client";
import { useHistory } from "react-router-dom";


import Accept from '../../schemas/Compound/Accept';
import Header from '../../components/HeaderComponent';
import { fetchGet } from '../../services/GettingData';
import ErrorDialog from '../../components/ErrorDialog';
import MoleculeView from '../../components/MoleculeView';
import IFinishInfo from '../../schemas/Compound/IFinishInfo';
import ProgressControl from '../../components/ProgressControl';
import IProgressInfo from '../../schemas/Compound/IProgressInfo';
import WebSocketConnection from '../Sockets/WebSocketConnection';
import { SearchStatus } from '../../schemas/Compound/SearchStatus';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';
import { getSession } from '../../services/Login';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import { ICompoundSearchModel } from '../../schemas/Compound/ICompoundSearchModel';
import { ICompoundSearchResponse } from '../../schemas/Compound/ICompoundSearchResponse';
import { IPublicationSearchResponse } from '../../schemas/Publication/IPublicationSearchResponse';
import '../../App.css';
import { Item, Item2 } from '../../styles';
import Config from '../../config.json';


interface LocationState {
    publicationId?: string;
    urn?: string;
}

type PublicationSearchProps = RouteComponentProps & {
    publicationId?: string;
    urn?: string;
};

export const PublicationSearchPage: React.FC<PublicationSearchProps> = (props) => {
    const history = useHistory();

    const location = useLocation<LocationState>();
    const [publicationSearchResponse, setPublicationSearchResponse] = React.useState<IPublicationSearchResponse>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isFound, setFound] = React.useState<boolean>(false);
    const [screenUrn, setScreenUrn] = React.useState<string>(location.state?.urn || '');
    const [urn, setUrn] = React.useState<string>(location.state?.urn || undefined);
    
    const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [searchBy, setSearchBy] = React.useState<number>(0);
    const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<ICompoundSearchResponse | undefined>(undefined);
    const [socketConnection, setSocketConnection] = React.useState<Socket | undefined>(undefined);
    const [searchCompound, setSearchCompound] = React.useState<string>();
    const [searchCompoundStatus, setSearchCompoundStatus] = React.useState<SearchStatus>();
    const [currentCompoundPage, setCurrentCompoundPage] = React.useState<number>(1);
    const searchCompoundRef = React.useRef<string | undefined>(searchCompound);


    
    const spectrumColumn: GridColDef[] = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            hide: true
        },
        {
            field: 'spectrum_string',
            headerName: '',
            flex: 0.9,
            sortable: false,
            disableColumnMenu: true,
        },
    ];
    
    

    React.useEffect(() => {

        const session = getSession();
        if (!session) history.push("/login")
          
        const fetchData = async () => {
            const publicationId = (props as any).match.params['publicationId'];
            if (urn)
                return;

            if (publicationId) {
                try {
                    const publication = await getPublicationById(publicationId);
                    setPublicationSearchResponse(publication);
                    setScreenUrn(publication.urn);
                    setUrn(publication.urn);
                } catch (error) {
                    setErrorMessage(String(error));
                    setErrorDialogOpened(true);
                }
            }
        };
        fetchData();
    }, [(props as any).match.params['publicationId']]);


    const closeErrorDialog = () => setErrorDialogOpened(false);


    const onError = (packet: any) => {
        console.error('/error in publication search', packet);
        showError(packet.error);
        setCompoundSearchResponse(undefined);
        setLoading(false);
    }


    const showError = (errorMessage: string) => {
        setErrorDialogOpened(true);
        setLoading(false);
        setFound(false);    
        setErrorMessage(errorMessage ? errorMessage : 'Not found');
    }


    const header = () => { return (<div style={{ height: '2em', alignItems: 'center' }}><span></span></div>); }

    const handleChangeSearchBy = (event: React.SyntheticEvent, newSearchBy: number) => {
        setSearchBy(newSearchBy);
    }


    const handleChangeDoi = (newDoi: string) => {
        setPublicationSearchResponse(undefined);
        setScreenUrn(newDoi.trim().replace('https://doi.org/', '').replace('http://doi.org/', '').toLowerCase());
    }


    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const gotoSpectrum = (spectrumId: string) => {
        window.open('/spectrum/' + spectrumId, '_blank');
    }


    const pageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => {
        get(searchCompound, value);
    }


    const getPublicationById = async (id: string): Promise<IPublicationSearchResponse> => {
        setFound(false);
        try {
            const publicationResponse = await fetchGet('/publication/id/' + id, true, true);
            if (!publicationResponse.ok)
                if (publicationResponse.status === 403) {
                    (props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
                }
                else {
                    if (publicationResponse.status === 404)
                        throw 'publication ' + id + ' not found';
                    throw publicationResponse.json();
                }
            const publication = await publicationResponse.json();
            if (!publication)
                throw 'publication not found by id ' + id;
            return publication;
        }
        catch (e: any) {
            if (e instanceof ExpiredAccountError)
                (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
            if (e instanceof NotAuthorizedError) {
                (props as any).history.push({ pathname: '/login', state: { backTo: '/publication-search', welcomeMessage: true } })
            } else {
                console.log('compoundSearchResponse', compoundSearchResponse);
                showError(e.toString());
                setPublicationSearchResponse(undefined);
                setCompoundSearchResponse(undefined);
                setLoading(false);
            }
        }
    }

 
    const onFinish = (data: IFinishInfo) => {
        console.log('/search/finish trigered', data);
        if (data.id === searchCompoundRef.current) {
            setSearchCompoundStatus(SearchStatus.Finished);
            if (socketConnection)
                socketConnection.off('/search/finish');
        }
    }


    const onProgress = (progress: IProgressInfo) => {
        if (progress.search_id === searchCompoundRef.current) {
            if (searchCompoundStatus !== SearchStatus.InProgress) {
                setSearchCompoundStatus(SearchStatus.InProgress);
            }
        }
    }


    const get = (search: string | undefined, page: number) => {
        if (search && socketConnection) {
            socketConnection.on('/search/get', (data) => onGet(data));
            setLoading(true);
            console.log('emit get with a page', search, page);
            socketConnection.emit("/search/get", {
                "id": search,
                "page": page,
                'per_page': Config.itemsPerPage
            });
        }
    }


    const onGet = (data: ICompoundSearchResponse) => {
        if (data.id === searchCompoundRef.current) {
            setCompoundSearchResponse(data);
            socketConnection.off('/search/get');
        }
        setFound(data.items.length > 0);
        setLoading(false);
    }


    React.useEffect(() => {
        if (searchCompoundStatus === SearchStatus.Finished)
            get(searchCompoundRef.current, currentCompoundPage);
    }, [searchCompoundStatus, currentCompoundPage]);


    const onAccept = (packet: Accept, connection: Socket) => {
        setSocketConnection(connection);
        if (packet.type === 'publication_compound') {
            setSearchCompoundStatus(SearchStatus.Started);
            setLoading(true);
            searchCompoundRef.current = packet.id;
            setSearchCompound(packet.id);
            console.log('/search/accepted compound', packet);
            connection.off('/search/accepted');
        }
        //connection.on('/search/get', (data) => onGet(data));
        connection.on('/search/progress', (data) => onProgress(data));
        connection.on('/search/finish', (data) => onFinish(data));
        connection.on('/error', (data) => onError(data));
    }



     const startSearchPublicationbyDoi = () => {
        setUrn(screenUrn);
     }

    React.useEffect(() => {
        console.log('start search publication by doi');
        if (urn)
        WebSocketConnection.start({
            "type": "publication_compound",
            "params": {
                urn: urn
            }}, onAccept, onError, 'publication-search' );
    }, [urn]);


    const helperDoiExamples = () => {
        const helperTexts = [
            {
                id: 1,
                value: "10.1021/jo502618g"
            }];

        return helperTexts.map((text) => (
            <span style={{ cursor: 'pointer' }} onClick={() => {
                handleChangeDoi(text.value)
            }} key={text.id} data-id={text.id} className="helper-text">
                {'ex. ' + text.value}
            </span>
        ));
    }


    return (
        <Grid container spacing={0} className='main-frame'>
            <ProgressControl showTime={true} isLoading={isLoading} />
            <Grid xs={12}>
                <Item2><div style={{ height: '2em' }}></div></Item2>
            </Grid>
            <Grid xs={12}>
                <Header title='Publication Search' showLogin={true} helpAddress='help#wips' />
            </Grid>
            <ErrorDialog isDialogOpened={isErrorDialogOpened}
                errorMessage={errorMessage}
                onClose={closeErrorDialog} />

            <>
                <Grid xs={12} style={{ marginTop: '4em' }}>
                    <div>{header()}
                        <Tabs value={searchBy}
                            onChange={handleChangeSearchBy}
                            style={{ padding: '0px' }}
                            aria-label="basic tabs">
                            <Tab label="Search by DOI" {...a11yProps(0)} />
                        </Tabs>
                        <TabPanel value={searchBy} index={0}>
                            <Item style={{ marginTop: '1em' }}>
                                <Grid container xs={12} style={{ marginTop: '2em' }} >
                                    <Grid xs={1}>
                                        <div style={{ marginTop: '1em' }}>
                                            DOI
                                        </div>
                                    </Grid>
                                    <Grid xs={10} md={3}>
                                        <TextField
                                            type="text"
                                            sx={{
                                                input: { "&::placeholder": { opacity: .5, }, }, label: { color: 'blue' }
                                            }}
                                            id="doi-query-text"
                                            label=""
                                            fullWidth
                                            onChange={(e) => { handleChangeDoi(e.target.value) }}
                                            value={screenUrn}
                                            helperText={helperDoiExamples()}
                                        />
                                    </Grid>
                                </Grid>
                            </Item>
                        </TabPanel>
                    </div>
                </Grid>
                <Grid xs={12} style={{ marginTop: '6em' }}>
                    <Button disabled={!screenUrn || isLoading} onClick={() => startSearchPublicationbyDoi()} variant="contained">Search</Button>
                </Grid>

                <Grid container md={12} xs={12}>
                    {isFound &&
                        <Grid md={12} xs={12}>
                            <Item id='block-stat'>
                                <div className='molecules-count'> {!isFound ? '' : <>Found publication with {compoundSearchResponse?.total} compounds:</>}</div>
                            </Item>
                        </Grid>
                    }
                </Grid>
            </>

            {isFound && !isLoading && <Grid md={12} style={{ marginTop: '2em', marginBottom: '0em' }}>
                {publicationSearchResponse && publicationSearchResponse.url}
            </Grid >
            }


            {isFound &&
                (isLoading ? <></> :
                    (<Grid container style={{ marginTop: '2em' }}>
                        {compoundSearchResponse?.items.map((compound: ICompoundSearchModel, index: number) => {
                            return (
                                <Grid md={12} container spacing={0} key={index}>
                                    <Grid md={3} style={{ marginBottom: '.5em', minWidth: "250px" }}>
                                        <Item key={compound.id} style={{ width: "250px", marginRight: 'auto' }}>
                                            <MoleculeView link={''}
                                                moleculeId={compound.id}
                                                isMoleculeInContainer={true}
                                                svgContent={compound.svg} />
                                        </Item>
                                        <div>{compound.compound_representation}</div>
                                    </Grid>
                                    <Grid md={9}>
                                        <Grid container xs={12} style={{ marginLeft: '1em' }}>
                                            <DataGrid
                                                disableColumnMenu
                                                sx={{
                                                    '.MuiDataGrid-cell:focus': {
                                                        outline: 'none'
                                                    },
                                                    '& .MuiDataGrid-row:hover': {
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                                autoHeight
                                                onCellDoubleClick={(params, event) => {
                                                    if (!event.ctrlKey) {
                                                        event.defaultMuiPrevented = true;
                                                    }
                                                    if (params.field === 'spectrum_string') gotoSpectrum(params.row.id);
                                                }}
                                                rows={compound.spectra}
                                                columns={spectrumColumn}
                                                disableSelectionOnClick
                                            /></Grid>
                                    </Grid>
                                </Grid>)
                        })}

                        {!isLoading &&
                            <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
                                <Pagination style={{ marginTop: '1em' }}
                                    count={Math.ceil((compoundSearchResponse ? compoundSearchResponse.total : 0) /
                                        (compoundSearchResponse ? compoundSearchResponse.pagination.per_page : Config.itemsPerPage))}
                                    page={compoundSearchResponse?.pagination.page} onChange={(e, v) => pageChangeHandle(e, v)} />
                            </Grid>}

                    </Grid>))
            }

            
        </Grid>
    );
}

export default PublicationSearchPage;
import React from 'react';
import { useLocation, useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Tooltip, Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { Socket } from "socket.io-client";

import { fetchPost } from '../../services/GettingData';
import ErrorDialog from '../../components/ErrorDialog';
import Header from '../../components/HeaderComponent';
import ProgressControl from '../../components/ProgressControl';
import { Item } from '../../styles';
import '../../App.css';
import WebSocketConnection from '../Sockets/WebSocketConnection';
import Accept from '../../schemas/Compound/Accept';
import { SearchStatus } from '../../schemas/Compound/SearchStatus';
import IProgressInfo from '../../schemas/Compound/IProgressInfo';
import IFinishInfo from '../../schemas/Compound/IFinishInfo';
import Config from '../../config.json';
import MoleculeView from '../../components/MoleculeView';
import { IRSpectrumItem } from '../../schemas/Spectrum/IRSpectrumItem';



interface IGetResponse {
    total: number;
    items: IRSpectrumItem[];
    id: string;
}

type IRSpectrumPreviewProps = {};

const IRSpectrumResultPage: React.FC<IRSpectrumPreviewProps> = () => {
    const location = useLocation() as { state: { irPeaks?: any } };
    const history = useHistory();
    const irPeaks = location.state?.irPeaks;

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [showMessage, setShowMessage] = React.useState<string>('');
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);
    const [svgContent, setSvgContent] = React.useState<string | null>(null);

    const [searchStatus, setSearchStatus] = React.useState<SearchStatus>();
    const searchStatusRef = React.useRef<string | undefined>(searchStatus);
    const [searchId, setSearchId] = React.useState<string>('');
    const searchRef = React.useRef<string | undefined>(searchId);
    const [connection, setConnection] = React.useState<Socket | undefined>(undefined);

    const [gotten, setGotten] = React.useState<number | undefined>(undefined);
    const gottenRef = React.useRef<number | undefined>(gotten);
    const [spectrumRows, setSpectrumRows] = React.useState<any[]>([]);
    const [searchResult, setSearchResult] = React.useState<IGetResponse | undefined>(undefined);
    const searchResultRef = React.useRef<IGetResponse | undefined>(searchResult);

    const closeErrorDialog = () => setDialogOpened(false);

    const showError = (errorMessage: string) => {
        setErrorMessage(errorMessage);
        setDialogOpened(true);
    };

    // Get a reference to calculate container width
    const dataGridContainerRef = React.useRef<HTMLDivElement>(null);
    const [columnWidth, setColumnWidth] = React.useState<number>(250);

    const updateColumnWidth = () => {
        if (dataGridContainerRef.current) {
            const containerWidth = dataGridContainerRef.current.clientWidth;
            setColumnWidth(Math.floor((containerWidth - 40) / 4));
        }
    };

    // Call on mount and window resize
    React.useEffect(() => {
        updateColumnWidth();
        window.addEventListener('resize', updateColumnWidth);
        return () => window.removeEventListener('resize', updateColumnWidth);
    }, []);


    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            hide: true
        },
        {
            field: 'title',
            headerName: 'Description',
            width: columnWidth,
            minWidth: 150,
            sortable: false,
            align: 'left',
            disableColumnMenu: true,
        },
        {
            field: 'compound',
            headerName: 'Compound',
            width: columnWidth,
            minWidth: 150,
            sortable: false,
            align: 'center',
            headerAlign: 'left',
            disableColumnMenu: true,
            renderCell: (params) => {
                if (params.value && params.value !== 'data:image/svg+xml,null')
                    return (
                        <div>
                            <MoleculeView svgContent={params.value.svg} isMoleculeInContainer={true} />
                            <a href={'/exact-search/' + params.row.compound_id}>
                                <img
                                    alt=''
                                    style={{ margin: '10px', maxWidth: '100%', height: 'auto' }}
                                    width="150px"
                                    src={params.value.svg}
                                />
                            </a>
                        </div>
                    );
                else return <div style={{ minHeight: '52px' }}></div>
            },
        },
        {
            field: 'main_wavenumbers',
            headerName: 'Main Wavenumbers',
            width: columnWidth,
            minWidth: 150,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'fit_score',
            headerName: 'Fit Score',
            width: columnWidth,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => {
                return params.value;
                //return `${Math.round(params.value * 100)}%`;
            },
        }
    ];


    const onAccept = (packet: Accept, connection: Socket) => {
        setConnection(connection);
        setSearchId(packet.id);
        connection.off('/search/accepted');
        connection.on('/search/progress', (data) => onProgress(data));
        connection.on('/search/finish', (data) => onFinish(data, packet.id));
        connection.on('/error', (data) => onError(data));
        setSearchStatus(SearchStatus.Started);

    };

    const onProgress = (progress: IProgressInfo) => {
        if (progress.search_id === searchRef.current) {
            let localRows = [...spectrumRows];
            setLoading(true);

            let added = 0;
            if (progress?.progress.items_chunk) {

                for (let i = 0; i < progress?.progress.items_chunk.length; i++) {
                    // Process each item from the response
                    let processed = {
                        id: progress?.progress.items_chunk[i].spectrum_id || '',
                        //spectrum_id: progress?.progress.items_chunk[i].spectrum_id || '',
                        title: progress?.progress.items_chunk[i].title || 'IR Spectrum',
                        compound: progress?.progress.items_chunk[i].compound || '',
                        peaks: progress?.progress.items_chunk[i].peaks || [],
                        main_wavenumbers: formatWavenumbers(progress?.progress.items_chunk[i].peaks),
                        fit_score: progress?.progress.items_chunk[i].fit_score || 0
                    };

                    // Check if this item is already in the rows
                    const existingIndex = localRows.findIndex(row => row.id === processed.id);
                    if (existingIndex === -1) {
                        localRows.push(processed);
                        added++;
                    }
                }
            }
            setSpectrumRows(localRows.sort((a, b) => b.fit_score - a.fit_score));

            if (!searchResultRef.current) {
                setSearchResult({
                    total: progress.progress.n_items,
                    items: [],
                    id: progress.search_id,
                });
            } else {
                setSearchResult(prev => ({
                    ...searchResultRef.current,
                    total: prev.total + added
                }));
            }


            if (searchStatusRef.current !== SearchStatus.InProgress) {
                console.log('set in [rogress status');
                setSearchStatus(SearchStatus.InProgress);
            }
            setLoading(false);

        }
    };
















    const onFinish = (packet: IFinishInfo, searchId: string) => {
        if (packet.id === searchId) {
            setSearchStatus(SearchStatus.Finished);
        }
    };


    const onError = (packet: any) => {
        console.error('on error', packet);
        showError(packet.error || 'An error occurred during the search');
        setLoading(false);
    };


    const onGet = (data: IGetResponse) => {
        if (searchStatusRef.current === SearchStatus.InProgress && data.total < 1) {
            if (searchResultRef.current)
                data.total = searchResult?.total ?? 0;
        }
        if (searchResultRef.current &&
            data.total < searchResultRef.current?.total &&
            searchResultRef.current?.total > 0) {
            data.total = searchResultRef.current?.total;
        }
        setSearchResult(data);
        let localRows = [...spectrumRows];

        if (data?.items) {
            for (let i = 0; i < data.items.length; i++) {
                // Process each item from the response
                let processed = {
                    id: data.items[i].spectrum_id || '',
                    title: data.items[i].title || 'IR Spectrum',
                    compound: data.items[i].compound || '',
                    peaks: data.items[i].peaks || [],
                    main_wavenumbers: formatWavenumbers(data.items[i].peaks),
                    fit_score: data.items[i].fit_score || 0
                };

                const existingIndex = localRows.findIndex(row => row.id === processed.id);
                if (existingIndex === -1) {
                    localRows.push(processed);
                }
            }
        }

        setSpectrumRows(localRows);
        setLoading(false);
    };

    const formatWavenumbers = (wavenumbers: number[] | string) => {
        if (!wavenumbers) return 'N/A';

        if (Array.isArray(wavenumbers)) {
            return wavenumbers.slice(0, 5).join(', ') +
                (wavenumbers.length > 10 ? '...' : '');
        }
        return wavenumbers;
    };


    const get = () => {
        console.log('get fired', searchId, connection);
        if (searchId && connection) {
            connection.off('/search/get');
            connection.on('/search/get', (data) => onGet(data));
            connection.emit("/search/get", {
                "id": searchId,
                "page": 1,
                'per_page': Config.itemsPerPage
            });
        }
    };


    const startIRSearch = async () => {
        setGotten(undefined);
        setSearchResult(undefined);
        setSpectrumRows([]);
        setLoading(true);

        WebSocketConnection.start({
            "type": "ir_spectrum",
            "params": {
                "x_req": irPeaks.x_req,
                "y_req": irPeaks.y_req,
                "approx": false,

            }
        }, onAccept, onError, 'ir-spectrum-search');

        return true;
    };


    const fetchSpectrumPreviews = async () => {
        if (!irPeaks) {
            showError('No IR peaks data found.');
            return;
        }

        setLoading(true);
        try {
            const pngResponse = await fetchPost(
                '/ir-spectrum/preview',
                JSON.stringify(irPeaks),
                true,
                true,
                undefined,
                '',
                3
            );

            const buffer = await pngResponse.arrayBuffer();
            const blob = new Blob([buffer], { type: 'image/png' });
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);

            // Start the search automatically
            startIRSearch();
        } catch (error) {
            console.error('Error fetching spectrum previews:', error);
            showError('Failed to fetch spectrum preview.');
            setLoading(false);
        }
    };

    // Update refs when state changes
    React.useEffect(() => {
        searchRef.current = searchId;
    }, [searchId]);

    React.useEffect(() => {
        searchStatusRef.current = searchStatus;
    }, [searchStatus]);

    React.useEffect(() => {
        gottenRef.current = gotten;
    }, [gotten]);

    React.useEffect(() => {
        searchResultRef.current = searchResult;
    }, [searchResult]);





    React.useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                history.push('/ir-spectrum-search');
            }
        });

        return () => {
            unlisten();
        };
    }, [history]);



    // Fetch spectrum preview image and start search
    React.useEffect(() => {
        if (irPeaks) {
            fetchSpectrumPreviews();

            // Cleanup function to revoke object URL when component unmounts
            return () => {
                if (imageUrl) {
                    URL.revokeObjectURL(imageUrl);
                }
            };
        }

    }, []);


    React.useEffect(() => {
        if (searchStatus === SearchStatus.Finished)
            get();
    }, [searchStatus]);


    return (
        <Grid container spacing={0} className='main-frame'>
            <ProgressControl isLoading={isLoading} showTime={true} />
            <Grid container>
                <Item><div style={{ height: '2em' }}></div></Item>
            </Grid>
            <ErrorDialog
                isDialogOpened={isDialogOpened}
                errorMessage={errorMessage}
                warningMessage={showMessage}
                onClose={closeErrorDialog}
            />
            <Grid item xs={12}>
                <Header title={'Infrared Spectrum Search Results'} helpAddress='help#htuirss' showLogin={true} />
            </Grid>

            {/* SVG Spectrum Preview section */}
            <Grid container spacing={0}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                        className='mol-container'
                        style={{
                            maxWidth: '800px',
                            width: '100%',
                            backgroundColor: '#f5f5f5',
                            padding: '0px',
                            borderRadius: '4px'
                        }}
                    >
                        {svgContent ? (
                            <div
                                className='graph-container'
                                dangerouslySetInnerHTML={{ __html: svgContent }}
                                style={{ width: '100%' }}
                            />
                        ) : (
                            imageUrl && (
                                <div className='graph-container'>
                                    <img
                                        className='graph-qc'
                                        src={imageUrl}
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                            display: 'block',
                                            margin: '0 auto'
                                        }}
                                        alt="Spectrum Preview"
                                    />
                                </div>
                            )
                        )}
                    </div>
                </Grid>
            </Grid>


            {/* Results Grid section */}
            <Grid item xs={12}>
                <Item style={{ padding: '0px' }}>
                    <Box sx={{ width: '100%' }} ref={dataGridContainerRef}>
                        <DataGrid
                            autoHeight
                            getRowHeight={() => 'auto'}
                            rows={spectrumRows || []}
                            columns={columns}
                            disableSelectionOnClick
                            hideFooter={true}
                            sx={{
                                minHeight: 400,
                                '& .MuiDataGrid-cell': {
                                    padding: '8px',
                                    textAlign: 'left'
                                }
                            }}
                        />

                    </Box>
                </Item>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1em' }}>
            </Grid>


            {/* Button actions section */}
            <Grid container spacing={2} style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Grid item xs={12} sm={4} style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '1rem'
                }}>{searchResult && (
                    <div>
                        Found {searchResult.total} spectrum.
                        {searchStatus === SearchStatus.InProgress && " (search in progress...)"}
                    </div>
                )}</Grid>


                <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={() => history.push({ pathname: '/ir-spectrum-preview', state: { irPeaks } })}
                    >
                        Edit Search
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IRSpectrumResultPage;
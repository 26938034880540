import ISession from "../schemas/Login/ISession";
import { fetchPost, BearerPrefix } from "./GettingData";
import WebSocketConnection from "../pages/Sockets/WebSocketConnection";
import NotAuthorizedError from "../schemas/Exception/NotAuthorizedError";
import AlreadyStartedError from "../schemas/Exception/AlreadyStartedError";

const LOCAL_STORAGE_ODANCHEM_USER = 'odanchem_session_user';
const LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN = 'odanchem_session_access_token';
const LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN = 'odanchem_session_refresh_token';
const LOCAL_STORAGE_ODANCHEM_REFRESH_STARTED = 'odanchem_session_refresh_started';
const TIME_TO_WAIT_PARALEL_REFRESH = 10000;



function getSession(blockRefresh: boolean=false): ISession | undefined {
    
    if (blockRefresh) {
        const refreshStarted = localStorage.getItem(LOCAL_STORAGE_ODANCHEM_REFRESH_STARTED);
        if (refreshStarted) {
            const refreshStartedDate = new Date(refreshStarted);
            const now = new Date();
            const diffInMinutes = (now.getTime() - refreshStartedDate.getTime()) / 1000 / 60;
            if (diffInMinutes < 2) {
                console.log('Refresh token was set less than 2 minutes ago');
                throw new AlreadyStartedError();
            }
        }
        localStorage.setItem(LOCAL_STORAGE_ODANCHEM_REFRESH_STARTED, new Date().toISOString());
        //console.log('Refresh token has been removed from local storage');
    }

    const token = localStorage.getItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN);
    if (!token) return undefined;
    const response = {
        access_token: localStorage.getItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN) as string,
        refresh_token: localStorage.getItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN) as string,
        user: { firstname: localStorage.getItem(LOCAL_STORAGE_ODANCHEM_USER) as string }
    }
    // if (blockRefreshToken) {
    //     localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN);
    //     console.log('refresh token has been removed from local storage');
    // }
    return response;
}


function updSession(firstName: string) {
    WebSocketConnection.getInstance(true);
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_USER, firstName);
}

function closeRefreshStarted() {
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_REFRESH_STARTED);
}

function keepSession(loginSession: ISession) {
    if (!loginSession) return;
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN, loginSession.refresh_token);
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN, loginSession.access_token);
    localStorage.setItem(LOCAL_STORAGE_ODANCHEM_USER, loginSession.user.firstname);
    WebSocketConnection.getInstance(true);
}


async function closeSession(refreshToken: string) {
    if (refreshToken)
        await fetchPost('/user/account/logout', JSON.stringify({
                refresh_token: refreshToken
            }), false, true);
}


function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function refreshToken(refreshToken: string = '', accessToken: string | undefined = undefined) {
    try {
        let session = getSession(true);

        if (!session)
            throw new NotAuthorizedError();

        if (accessToken && (BearerPrefix + session.access_token) !== accessToken && session.access_token !== accessToken) {
            console.warn('access token has already changed, ', accessToken);
            return;
        }
        const response = await fetchPost('/user/token/refresh', JSON.stringify({
            refresh_token: session?.refresh_token
        }), false, false);

        if (response?.ok) {
            let newSession = await response.json();
            keepSession(newSession);
            console.log('refresh token has been updated');
            closeRefreshStarted();
        } else if (response?.status === 403) {
            let updatedSession = getSession();
            if (refreshToken && updatedSession!.refresh_token !== refreshToken) {
                console.warn('refresh token has already refreshed', refreshToken);
                return;
            }
            console.error('session token is up to date');
            throw new NotAuthorizedError();
        }
    } catch (error) {
        if (error instanceof AlreadyStartedError) {
            console.warn('Refresh token process already started less than 2 minutes ago.');
            await sleep(TIME_TO_WAIT_PARALEL_REFRESH);
            
        } else {
            console.error('Error refreshing token:', error);
            closeRefreshStarted();
            logout();
            throw new NotAuthorizedError();
        }
    }
}


function logout() {
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_USER);
    localStorage.removeItem(LOCAL_STORAGE_ODANCHEM_REFRESH_STARTED);
    closeSession(localStorage.getItem(LOCAL_STORAGE_ODANCHEM_REFRESH_TOKEN) as string);
}

export { getSession, keepSession, logout, refreshToken, updSession, closeSession, closeRefreshStarted };
